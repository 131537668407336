import { computed } from "vue";
import { useMq } from "vue3-mq";

export function useMqUtils() {
  const mq = useMq();
  const isMobile = computed(() => ["xs", "sm", "md"].includes(mq.current));

  return {
    isMobile,
  };
}
